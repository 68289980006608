<template>
  <tr class="table-head">
    <slot></slot>
  </tr>
</template>

<style scoped>
.table-head {
  height: 40px;
  color: var(--s-text-color-2);
  font-weight: 600;
  font-size: 0.8rem;
}

.table-head td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>