/* Flex */

.s-d-flex {
  display: flex;
}

.s-d-inline {
  display: inline;
}

.s-flex-1 {
  flex: 1;
}

.s-flex-2 {
  flex: 2;
}

.s-flex-3 {
  flex: 3;
}

.s-flex-4 {
  flex: 4;
}

.s-flex-5 {
  flex: 5;
}

.s-flex-6 {
  flex: 6;
}

.s-flex-column {
  flex-direction: column;
}

.s-flex-row {
  flex-direction: row;
}

.s-flex-wrap {
  flex-wrap: wrap;
}

/* Alignment */

.s-align-center {
  align-items: center;
}

.s-align-start {
  align-items: flex-start;
}

.s-align-end {
  align-items: flex-end;
}

.s-align-baseline {
  align-items: baseline;
}

.s-align-stretch {
  align-items: stretch;
}

.s-justify-center {
  justify-content: center;
}

.s-justify-start {
  justify-content: flex-start;
}

.s-justify-end {
  justify-content: flex-end;
}

.s-justify-space-between {
  justify-content: space-between;
}

.s-justify-space-around {
  justify-content: space-around;
}

/* Text */

.s-text-center {
  text-align: center !important;
}

.s-text-left {
  text-align: left !important;
}

.s-text-right {
  text-align: right !important;
}

/* Overflow */

.s-overflow-auto {
  overflow: auto;
}

.s-overflow-hidden {
  overflow: hidden;
}

.s-overflow-visible {
  overflow: visible;
}
