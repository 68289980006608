.siggma-notify-info {
  position: relative;
}

.siggma-notify-info-number {
  top: -3px;
  right: -12px;
  color: #fff;
  height: 15px;
  padding: 1px 5px;
  position: absolute;
  min-width: 11px;
  font-size: 10px;
  background: red;
  text-align: center;
  font-weight: bolder;
  border-radius: 50%;
}

.siggma-notify-header {
  color: #fff;
  height: 40px;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bolder;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
}

.siggma-notify-header span {
  padding: 0 5px;
}

.siggma-notify-refresh,
.siggma-notify-read-all {
  cursor: pointer;
}

.siggma-notify-title {
  flex: 1;
}

.siggma-notify-number {
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.siggma-notify-clean {
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  color: #aaa;
  font-size: 13px;
  padding: 15px 20px;
  position: relative;
  text-align: center;
  margin: 100px auto;
  width: 200px;
}

.siggma-notify {
  min-height: 400px;
  max-height: 400px;
  width: 400px;
  overflow-y: auto;
}

.siggma-notify-item {
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  color: #000;
  font-size: 13px;
  padding: 15px 20px;
  margin: 4px 10px;
  position: relative;
}

.siggma-notify-item-remove {
  position: absolute;
  top: 0px;
  right: 2px;
  cursor: pointer;
  visibility: hidden;
}

.siggma-notify-item-time {
  position: absolute;
  top: 2px;
  right: 20px;
  font-size: 10px;
  color: #94999c;
}

.siggma-notify-item a {
  color: blue;
}
