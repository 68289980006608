/* Margin auto */

.s-ma-auto {
  margin: auto !important;
}

.s-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.s-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Margin 0 */

.s-ma-0 {
  margin: 0 !important;
}

.s-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.s-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.s-mt-0 {
  margin-top: 0 !important;
}

.s-mr-0 {
  margin-right: 0 !important;
}

.s-mb-0 {
  margin-bottom: 0 !important;
}

.s-ml-0 {
  margin-left: 0 !important;
}

/* Margin 4px */

.s-ma-1 {
  margin: 4px !important;
}

.s-mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.s-my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.s-mt-1 {
  margin-top: 4px !important;
}

.s-mr-1 {
  margin-right: 4px !important;
}

.s-mb-1 {
  margin-bottom: 4px !important;
}

.s-ml-1 {
  margin-left: 4px !important;
}

/* Margin 8px */

.s-ma-2 {
  margin: 8px !important;
}

.s-mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.s-my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.s-mt-2 {
  margin-top: 8px !important;
}

.s-mr-2 {
  margin-right: 8px !important;
}

.s-mb-2 {
  margin-bottom: 8px !important;
}

.s-ml-2 {
  margin-left: 8px !important;
}

/* Margin 12px */

.s-ma-3 {
  margin: 12px !important;
}

.s-mx-3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.s-my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.s-mt-3 {
  margin-top: 12px !important;
}

.s-mr-3 {
  margin-right: 12px !important;
}

.s-mb-3 {
  margin-bottom: 12px !important;
}

.s-ml-3 {
  margin-left: 12px !important;
}

/* Margin 16px */

.s-ma-4 {
  margin: 16px !important;
}

.s-mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.s-my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.s-mt-4 {
  margin-top: 16px !important;
}

.s-mr-4 {
  margin-right: 16px !important;
}

.s-mb-4 {
  margin-bottom: 16px !important;
}

.s-ml-4 {
  margin-left: 16px !important;
}

/* Margin 20px */

.s-ma-5 {
  margin: 20px !important;
}

.s-mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.s-my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.s-mt-5 {
  margin-top: 20px !important;
}

.s-mr-5 {
  margin-right: 20px !important;
}

.s-mb-5 {
  margin-bottom: 20px !important;
}

.s-ml-5 {
  margin-left: 20px !important;
}

/* Margin 24px */

.s-ma-6 {
  margin: 24px !important;
}

.s-mx-6 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.s-my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.s-mt-6 {
  margin-top: 24px !important;
}

.s-mr-6 {
  margin-right: 24px !important;
}

.s-mb-6 {
  margin-bottom: 24px !important;
}

.s-ml-6 {
  margin-left: 24px !important;
}

/* Margin 28px */

.s-ma-7 {
  margin: 28px !important;
}

.s-mx-7 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.s-my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.s-mt-7 {
  margin-top: 28px !important;
}

.s-mr-7 {
  margin-right: 28px !important;
}

.s-mb-7 {
  margin-bottom: 28px !important;
}

.s-ml-7 {
  margin-left: 28px !important;
}

/* Margin 32px */

.s-ma-8 {
  margin: 32px !important;
}

.s-mx-8 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.s-my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.s-mt-8 {
  margin-top: 32px !important;
}

.s-mr-8 {
  margin-right: 32px !important;
}

.s-mb-8 {
  margin-bottom: 32px !important;
}

.s-ml-8 {
  margin-left: 32px !important;
}

/* Margin 36px */

.s-ma-9 {
  margin: 36px !important;
}

.s-mx-9 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.s-my-9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.s-mt-9 {
  margin-top: 36px !important;
}

.s-mr-9 {
  margin-right: 36px !important;
}

.s-mb-9 {
  margin-bottom: 36px !important;
}

.s-ml-9 {
  margin-left: 36px !important;
}

/* Margin 40px */

.s-ma-10 {
  margin: 40px !important;
}

.s-mx-10 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.s-my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.s-mt-10 {
  margin-top: 40px !important;
}

.s-mr-10 {
  margin-right: 40px !important;
}

.s-mb-10 {
  margin-bottom: 40px !important;
}

.s-ml-10 {
  margin-left: 40px !important;
}

/* Margin 44px */

.s-ma-11 {
  margin: 44px !important;
}

.s-mx-11 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.s-my-11 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.s-mt-11 {
  margin-top: 44px !important;
}

.s-mr-11 {
  margin-right: 44px !important;
}

.s-mb-11 {
  margin-bottom: 44px !important;
}

.s-ml-11 {
  margin-left: 44px !important;
}

/* Margin 48px */

.s-ma-12 {
  margin: 48px !important;
}

.s-mx-12 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.s-my-12 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.s-mt-12 {
  margin-top: 48px !important;
}

.s-mr-12 {
  margin-right: 48px !important;
}

.s-mb-12 {
  margin-bottom: 48px !important;
}

.s-ml-12 {
  margin-left: 48px !important;
}

/* Margin 52px */

.s-ma-13 {
  margin: 52px !important;
}

.s-mx-13 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}

.s-my-13 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.s-mt-13 {
  margin-top: 52px !important;
}

.s-mr-13 {
  margin-right: 52px !important;
}

.s-mb-13 {
  margin-bottom: 52px !important;
}

.s-ml-13 {
  margin-left: 52px !important;
}

/* Margin 56px */

.s-ma-14 {
  margin: 56px !important;
}

.s-mx-14 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.s-my-14 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.s-mt-14 {
  margin-top: 56px !important;
}

.s-mr-14 {
  margin-right: 56px !important;
}

.s-mb-14 {
  margin-bottom: 56px !important;
}

.s-ml-14 {
  margin-left: 56px !important;
}

/* Margin 60px */

.s-ma-15 {
  margin: 60px !important;
}

.s-mx-15 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.s-my-15 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.s-mt-15 {
  margin-top: 60px !important;
}

.s-mr-15 {
  margin-right: 60px !important;
}

.s-mb-15 {
  margin-bottom: 60px !important;
}

.s-ml-15 {
  margin-left: 60px !important;
}

/* Margin 64px */

.s-ma-16 {
  margin: 64px !important;
}

.s-mx-16 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.s-my-16 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.s-mt-16 {
  margin-top: 64px !important;
}

.s-mr-16 {
  margin-right: 64px !important;
}

.s-mb-16 {
  margin-bottom: 64px !important;
}

.s-ml-16 {
  margin-left: 64px !important;
}
