:root {
  --s-text-color: #707070;
  --s-text-color-2: #464646;

  --s-box-primary-color: white;
  --s-box-primary-hover: #c2c2c2;
  --s-box-primary-bg-color: #535353;

  --s-box-bg-color: white;

  --s-box-line-bg-color: white;
  --s-box-line-border-color: #e0e0e0;

  --s-text-size-body-1: 14px;
  --s-text-size-body-2: 16px;
}

@import "tags.css";
@import "box.css";
@import "menu-tela.css";
@import "mensagens.css";
@import "siggma-notify.css";
@import "adapters.css";
@import "margin.css";
@import "padding.css";
@import "flex.css";
@import "grid.css";
@import "notify.css";

body {
  overflow-x: hidden;
  overflow-y: auto;
}

label > input {
  position: relative;
  top: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--s-text-color-2);
  margin: 5px;
}

@media screen and (max-width: 1023px) {
  body {
    overflow-x: auto;
    background-size: 60vmin;
  }
}

@media screen and (max-width: 445px), (max-height: 495px) {
  body {
    background-image: none !important;
  }
}

.s-icon {
  color: var(--s-text-color);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.s-icon:hover {
  background: rgb(226, 226, 226);
  color: black;
}

.s-icon-dark {
  color: white;
}

.s-icon-dark:hover {
  color: var(--s-text-color);
}

.s-text-trunc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.s-button-flat {
  background: transparent;
  box-shadow: none;
  border: 1px solid transparent;
}

.s-text-body-1 {
  font-size: var(--s-text-size-body-1);
}

.s-text-body-2 {
  font-size: var(--s-text-size-body-2);
}

.s-text-bold {
  font-weight: bold;
}

.s-text-normal {
  font-weight: normal;
}

.s-text--primary {
  color: var(--s-text-color);
}

.s-text--secondary {
  color: var(--s-text-color-2);
}

/* Painel menu novo */
.painel-menu {
  top: 41px;
  width: 100%;
  width: calc(100% - 10px);
  height: calc(100% - 40px);
  z-index: 600001;
  overflow: hidden;
  position: fixed;
  font-size: 14px;
  visibility: hidden;
  background: none repeat scroll 0% 0% #fff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12);
  user-select: none;
  -webkit-user-select: none;
}

.title-directive {
  position: fixed;
  z-index: 100;
  border: 1px solid gray;
  padding: 0px 10px 2px 10px;
  border-radius: 7px;
  background-color: rgb(199, 241, 247);
}

.title-directive:after {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgb(170, 166, 166);
  bottom: -11px;
  left: 7px;
}

.s-yanda-icon-help {
  padding-right: 5px;
  opacity: 0.5;
  float: right;
  cursor: pointer;
  font-size: 20px;
  margin-top: -6px;
}

.s-yanda-icon-help:hover {
  opacity: 1;
}

.s-capitalize {
  text-transform: capitalize;
}

.s-visualizar-imagem {
  position: absolute;
  top: 0;
  padding-top: 20px;
  min-height: calc(100% - 20px);
  width: 100%;
  text-align: center;
  background-color: #000;
  z-index: 999999;
}

.s-visualizar-imagem > img {
  object-fit: cover;
  max-width: 100%;
}

.s-visualizar-imagem > i {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 50px !important;
  cursor: pointer;
  color: #fff;
  background: #000;
}
