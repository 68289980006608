/* Padding auto */

.s-pa-auto {
  padding: auto !important;
}

.s-px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.s-py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

/* Padding 0 */

.s-pa-0 {
  padding: 0 !important;
}

.s-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.s-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.s-pt-0 {
  padding-top: 0 !important;
}

.s-pr-0 {
  padding-right: 0 !important;
}

.s-pb-0 {
  padding-bottom: 0 !important;
}

.s-pl-0 {
  padding-left: 0 !important;
}

/* Padding 4px */

.s-pa-1 {
  padding: 4px !important;
}

.s-px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.s-py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.s-pt-1 {
  padding-top: 4px !important;
}

.s-pr-1 {
  padding-right: 4px !important;
}

.s-pb-1 {
  padding-bottom: 4px !important;
}

.s-pl-1 {
  padding-left: 4px !important;
}

/* Padding 8px */

.s-pa-2 {
  padding: 8px !important;
}

.s-px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.s-py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.s-pt-2 {
  padding-top: 8px !important;
}

.s-pr-2 {
  padding-right: 8px !important;
}

.s-pb-2 {
  padding-bottom: 8px !important;
}

.s-pl-2 {
  padding-left: 8px !important;
}

/* Padding 12px */

.s-pa-3 {
  padding: 12px !important;
}

.s-px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.s-py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.s-pt-3 {
  padding-top: 12px !important;
}

.s-pr-3 {
  padding-right: 12px !important;
}

.s-pb-3 {
  padding-bottom: 12px !important;
}

.s-pl-3 {
  padding-left: 12px !important;
}

/* Padding 16px */

.s-pa-4 {
  padding: 16px !important;
}

.s-px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.s-py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.s-pt-4 {
  padding-top: 16px !important;
}

.s-pr-4 {
  padding-right: 16px !important;
}

.s-pb-4 {
  padding-bottom: 16px !important;
}

.s-pl-4 {
  padding-left: 16px !important;
}

/* Padding 20px */

.s-pa-5 {
  padding: 20px !important;
}

.s-px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.s-py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.s-pt-5 {
  padding-top: 20px !important;
}

.s-pr-5 {
  padding-right: 20px !important;
}

.s-pb-5 {
  padding-bottom: 20px !important;
}

.s-pl-5 {
  padding-left: 20px !important;
}

/* Padding 24px */

.s-pa-6 {
  padding: 24px !important;
}

.s-px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.s-py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.s-pt-6 {
  padding-top: 24px !important;
}

.s-pr-6 {
  padding-right: 24px !important;
}

.s-pb-6 {
  padding-bottom: 24px !important;
}

.s-pl-6 {
  padding-left: 24px !important;
}

/* Padding 28px */

.s-pa-7 {
  padding: 28px !important;
}

.s-px-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.s-py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.s-pt-7 {
  padding-top: 28px !important;
}

.s-pr-7 {
  padding-right: 28px !important;
}

.s-pb-7 {
  padding-bottom: 28px !important;
}

.s-pl-7 {
  padding-left: 28px !important;
}

/* Padding 32px */

.s-pa-8 {
  padding: 32px !important;
}

.s-px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.s-py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.s-pt-8 {
  padding-top: 32px !important;
}

.s-pr-8 {
  padding-right: 32px !important;
}

.s-pb-8 {
  padding-bottom: 32px !important;
}

.s-pl-8 {
  padding-left: 32px !important;
}

/* Padding 36px */

.s-pa-9 {
  padding: 36px !important;
}

.s-px-9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.s-py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.s-pt-9 {
  padding-top: 36px !important;
}

.s-pr-9 {
  padding-right: 36px !important;
}

.s-pb-9 {
  padding-bottom: 36px !important;
}

.s-pl-9 {
  padding-left: 36px !important;
}

/* Padding 40px */

.s-pa-10 {
  padding: 40px !important;
}

.s-px-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.s-py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.s-pt-10 {
  padding-top: 40px !important;
}

.s-pr-10 {
  padding-right: 40px !important;
}

.s-pb-10 {
  padding-bottom: 40px !important;
}

.s-pl-10 {
  padding-left: 40px !important;
}

/* Padding 44px */

.s-pa-11 {
  padding: 44px !important;
}

.s-px-11 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.s-py-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.s-pt-11 {
  padding-top: 44px !important;
}

.s-pr-11 {
  padding-right: 44px !important;
}

.s-pb-11 {
  padding-bottom: 44px !important;
}

.s-pl-11 {
  padding-left: 44px !important;
}

/* Padding 48px */

.s-pa-12 {
  padding: 48px !important;
}

.s-px-12 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.s-py-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.s-pt-12 {
  padding-top: 48px !important;
}

.s-pr-12 {
  padding-right: 48px !important;
}

.s-pb-12 {
  padding-bottom: 48px !important;
}

.s-pl-12 {
  padding-left: 48px !important;
}

/* Padding 52px */

.s-pa-13 {
  padding: 52px !important;
}

.s-px-13 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.s-py-13 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.s-pt-13 {
  padding-top: 52px !important;
}

.s-pr-13 {
  padding-right: 52px !important;
}

.s-pb-13 {
  padding-bottom: 52px !important;
}

.s-pl-13 {
  padding-left: 52px !important;
}

/* Padding 56px */

.s-pa-14 {
  padding: 56px !important;
}

.s-px-14 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.s-py-14 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.s-pt-14 {
  padding-top: 56px !important;
}

.s-pr-14 {
  padding-right: 56px !important;
}

.s-pb-14 {
  padding-bottom: 56px !important;
}

.s-pl-14 {
  padding-left: 56px !important;
}

/* Padding 60px */

.s-pa-15 {
  padding: 60px !important;
}

.s-px-15 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.s-py-15 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.s-pt-15 {
  padding-top: 60px !important;
}

.s-pr-15 {
  padding-right: 60px !important;
}

.s-pb-15 {
  padding-bottom: 60px !important;
}

.s-pl-15 {
  padding-left: 60px !important;
}

/* Padding 64px */

.s-pa-16 {
  padding: 64px !important;
}

.s-px-16 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.s-py-16 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.s-pt-16 {
  padding-top: 64px !important;
}

.s-pr-16 {
  padding-right: 64px !important;
}

.s-pb-16 {
  padding-bottom: 64px !important;
}

.s-pl-16 {
  padding-left: 64px !important;
}
