.s-mensagens {
  position: absolute;
  top: 0;
  min-height: 100%;
  width: 100%;
  z-index: 999999;
}

.s-mensagens-block {
  position: absolute;
  top: 0;
  min-height: 100%;
  width: 100%;
  text-align: center;
  background-color: #000;
  opacity: 0.8;
}

.s-mensagens-box {
  position: relative;
  margin: 60px auto;
  width: calc(100% - 10px);
  max-width: 600px;
  min-height: 400px;
  height: 90%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.s-mensagens-content {
  padding: 10px;
  flex: 1;
  overflow-y: auto;
  margin: 10px 0 0;
}

.s-mensagens-footer {
  border-top: 1px solid #aaa;
  padding: 10px;
  text-align: right;
}
