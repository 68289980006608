.s-tag {
  height: 18px;
  padding: 0px 5px;
  overflow: hidden;
  max-width: 240px;
  font-size: 10px;
  border: 1px solid rgb(46, 85, 100);
  background: rgb(111, 208, 247);
  line-height: 14px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.s-tag-success {
  color: #000;
  border: 1px solid #5fa275;
  background: #a1e4b7;
}

.s-tag-danger {
  color: white;
  border: 1px solid rgb(201, 62, 37);
  background: orangered;
}

.s-tag-warning {
  color: #000;
  border: 1px solid #ba913e;
  background: #ffd683;
}

.s-tag-extra {
  color: #000;
  border: 1px solid rgb(119, 109, 50);
  background: rgb(189, 207, 84);
}

.s-tag-grey {
  color: white;
  border: 1px solid rgb(59, 59, 58);
  background: rgb(122, 122, 119);
}

.s-tag-info {
  color: white;
  border: 1px solid rgb(59, 59, 58);
  background: darkslateblue;
}

.s-tag-info-text {
  color: darkslateblue;
  border: 0;
  background: white;
}

.s-tag-info-outlined {
  color: darkslateblue;
  border: 1px solid darkslateblue;
  background: white;
}

.s-tag-small {
  height: 14px;
  padding: 0px 3px;
  max-width: 220px;
  font-size: 8px;
  line-height: 10px;
  font-weight: normal;
}

.s-tag-caps {
  text-transform: uppercase;
}
