.s-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
}

.s-g-0 {
  --bs-gutter-x: 0;
}

.s-g-1 {
  --bs-gutter-x: 0.25rem;
}

.s-g-2 {
  --bs-gutter-x: 0.5rem;
}

.s-g-3 {
  --bs-gutter-x: 1rem;
}

.s-row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.s-col {
  flex: 1 0 0%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.s-col-1 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(8.3333333333% - var(--bs-gutter-x));
}

.s-col-2 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(16.6666666667% - var(--bs-gutter-x));
}

.s-col-3 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(25% - var(--bs-gutter-x));
}

.s-col-4 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(33.3333333333% - var(--bs-gutter-x));
}

.s-col-5 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(41.6666666667% - var(--bs-gutter-x));
}

.s-col-6 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(50% - var(--bs-gutter-x));
}

.s-col-7 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(58.3333333333% - var(--bs-gutter-x));
}

.s-col-8 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(66.6666666667% - var(--bs-gutter-x));
}

.s-col-9 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(75% - var(--bs-gutter-x));
}

.s-col-10 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(83.3333333333% - var(--bs-gutter-x));
}

.s-col-12 {
  flex: 1 0 auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: calc(100% - var(--bs-gutter-x));
}
