.select2-container--open .select2-selection,
.select2-container--focus .select2-selection {
  border-color: #505050;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.5) inset, 0 0 10px #222;
}

.select2-dropdown,
.select2-container--default,
.select2-selection__rendered {
  color: #000 !important;
  font-size: 14px !important;
}

.select2-selection__choice {
  font-size: 12px !important;
}

.select2-selection__rendered {
  max-height: 110px;
  overflow-y: auto !important;
}

.select2-hidden-accessible {
  height: inherit !important;
  width: 100px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: normal;
  font-size: 18px;
  margin-top: -2px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: normal;
  margin-top: 1px;
  margin-right: 10px;
  font-size: 18px;
}

.fc-list-table td {
  vertical-align: middle;
  padding: 5px 6px;
}

/* Regra existente tambem no ajanyanda */
.fc-event,
.fc-event:hover,
.ui-widge {
  color: #000;
}

.fc-colaborador-view {
  display: flex;
  overflow: auto;
}

.fc-colaborador-view > div:not(.fc-list-empty-wrap2) {
  border: 1px solid #ddd;
  border-left: 0;
  min-width: 222px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fc-colaborador-view > div:first-child {
  border-left: 1px solid #ddd;
}

.fc-colaborador-header {
  background: #eee;
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 32px;
  box-sizing: border-box;
}

.fc-colaborador-list {
  flex: 1;
  padding: 5px;
  overflow: auto;
  overflow-x: hidden;
  column-count: unset;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.fc-colaborador-list-nowrap {
  flex-wrap: nowrap;
  flex-direction: column;
}

.fc-colaborador-list > div {
  break-inside: avoid-column;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  width: 200px;
  margin: 0 2px 0;
}

.fc-colaborador-event {
  border: solid 1px #aaa;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
  height: 100px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.fc-colaborador-event-empty {
  margin-bottom: 5px;
  min-height: 40px;
  height: 100%;
  font-weight: normal;
  display: flex;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center;
}

.fc-colaborador-event-empty > div {
  display: flex;
  margin-bottom: 5px;
}

.fc-colaborador-event-empty:hover > div {
  text-decoration: underline;
}

.fc-colaborador-event:hover,
.fc-colaborador-event:active {
  box-shadow: 0 1px 3px #aaa;
}

.fc-colaborador-event > div:first-child {
  font-weight: 700;
  display: flex;
  align-items: center;
}

.fc-colaborador-event > div:first-child :first-child {
  flex: 1;
}

.fc-colaborador-event > div:nth-child(2) {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fc-colaborador-event > div:last-child {
  /* font-weight: 600; */
  text-align: right;
}

.fc-colaborador-event-icon {
  width: 30px;
  display: inline-block;
  text-align: center;
  padding: 3px 0 5px;
  border-radius: 4px;
  position: relative;
  top: -2px;
}

/* element-ui */
label.el-checkbox-button {
  padding-right: 0;
}

.el-loading-mask {
  background-color: unset;
}

/* jqGrid */
.ui-jqgrid .ui-jqgrid-htable .ui-th-div {
  height: auto;
  /* Default: 17px - Height automatico dos headers com <br> */
}

.mdi-14px.mdi-set,
.mdi-14px.mdi:before {
  font-size: 14px;
}
