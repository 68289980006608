.s-menu {
  min-width: 160px;
}

.s-menu ul {
  padding: 0 5px;
  margin: 8px 0;
}

.s-menu li {
  cursor: pointer;
  padding: 5px;
  overflow: hidden;
  list-style: none;
  white-space: nowrap;
  border-radius: 4px;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.s-menu li {
  color: var(--s-text-color-2);
  cursor: pointer;
  font-size: 12px;
  padding-right: 4px;
}

.s-menu li[disabled] {
  cursor: default;
}

.s-menu li:hover:not([disabled]) {
  background-color: var(--s-box-primary-hover);
}

.s-menu-selected {
  color: var(--s-box-primary-color) !important;
  background: var(--s-box-primary-bg-color) !important;
}
