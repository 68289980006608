.notify {
  min-width: 300px;
  max-width: 500px;
  min-height: 60px;

  /* O Firefox não entendeu o min-height quando o display for table */
  height: 60px;

  position: fixed;
  bottom: 30px;
  right: 5px;
  z-index: 999999;
  border-radius: 10px;
  border: 1px solid;
  opacity: 0.98;
  cursor: pointer;
  font-family: Verdana, Helvetica, Tahoma, Arial;
  font-size: var(--s-text-size-body-2);
  color: #464646;
  display: table;
}

.notify:hover {
  opacity: 0.50;
}

.notify-success {
  border-color: #2ebe20;
  box-shadow: 0px 0px 10px #3cbe2f;
  background-color: #deffd8;
  color: #396228;
}

.notify-error {
  border-color: #ed87ca;
  box-shadow: 0px 0px 10px #ed3ab0;
  background-color: #ffc6df;
  color: #bf2e0b;
}
.notify-info {
  border-color: #0058d9;
  box-shadow: 0px 0px 10px #003fae;
  background-color: #e7e3ff;
  color: #313f92;
}
.notify-warning {
  border-color: #d4b22d;
  box-shadow: 0px 0px 10px #beb92f;
  background-color: #fffee3;
  color: #626028;
}

.notify-black {
  border-color: #444;
  box-shadow: 0px 0px 10px #555;
  background-color: #000;
  color: #fff;
}

.notify-img {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 5px;
  left: 5px;
}

.notify-text {
  padding: 10px 5px 5px 53px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

/*@-webkit-keyframes fadein {
  0%   {visibility:hidden;opacity: 0;}
  10%  {opacity: .10;}
  20%  {opacity: .20;}
  30%  {opacity: .30;}
  40%  {opacity: .40;}
  50%  {opacity: .50;}
  60%  {opacity: .60;}
  70%  {opacity: .70;}
  80%  {opacity: .80;}
  90%  {opacity: .90;}
  100% {opacity: .98;}
}

@-webkit-keyframes fadeout {
  0%   {visibility: visible;opacity:.98}
  10%  {opacity: .90;}
  20%  {opacity: .80;}
  30%  {opacity: .70;}
  40%  {opacity: .60;}
  50%  {opacity: .50;}
  60%  {opacity: .40;}
  70%  {opacity: .30;}
  80%  {opacity: .20;}
  90%  {opacity: .10;}
  100% {opacity: .0;}
}*/
