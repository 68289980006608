.s-card {
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: var(--s-box-bg-color);
  margin: 8px;
  transition: box-shadow 0.2s;
}

.s-card-hover:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12), 0 5px 10px rgba(0, 0, 0, 0.2);
}

.s-card-line {
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  background-color: var(--s-box-bg-color);
  margin: 8px;
  border: 1px solid var(--s-box-line-border-color);
  border-radius: 4px;
}

.s-box-line {
  height: 36px;
  margin: 0 auto;
  display: flex;
  padding: 8px 10px;
  background: var(--s-box-line-bg-color);
  align-items: center;
  border-left: 1px solid var(--s-box-line-border-color);
  border-right: 1px solid var(--s-box-line-border-color);
  border-bottom: 1px solid var(--s-box-line-border-color);
  border-radius: 4px;
}

.s-box-line:first-of-type {
  border-top: 1px solid var(--s-box-line-border-color);
}

.s-box-line-small {
  height: 25px;
  padding: 6px 8px;
}

.s-card-caption {
  align-items: center;
  height: auto;
  overflow: auto;
  flex: 1;
  padding: 5px;
}

.s-card-caption h4 {
  padding: 0px;
  margin: 2px;
}

.s-box-line-caption {
  height: 15px;
  margin: 2px;
  font-size: 10px;
  display: flex;
}

.s-box-line-value {
  align-items: center;
  display: flex;
  height: 25px;
  padding-left: 25px;
}

.s-overlay {
  opacity: 0.5;
  background-color: #000;
}
